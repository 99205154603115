import React from 'react';
import { Nav, Image } from 'react-bootstrap';
import * as S from './styles';
import { toast } from 'react-hot-toast';

export const Provenance = (props: any) => {
  const _renderImage = () => {
    return (
      <div
        style={S.ImageContainerStyle}
        onClick={() => {
          toast('Work In Progress', { duration: 3000, icon: '⚒️' });
        }}
      >
        <Image src={props?.provImage} />
      </div>
    );
  };

  const _rederImageTitle = () => {
    return <div style={S.TitleStyle}>{props?.imageTitle}</div>;
  };

  const _rederImageSubtitle = () => {
    return <div style={S.SubtitleStyle}>{props?.imageSubtitle}</div>;
  };

  const _renderNote = () => {
    return (
      <div style={S.NoteContainerStyle}>
        <div style={S.NoteTitleStyle}>{props?.noteTitle}</div>
        <div style={S.NoteContentStyle}>{props?.noteContent}</div>
        <div style={S.NoteButtonContainerStyle}>
          <div
            style={S.NoteButtonStyle}
            onClick={() => {
              toast('Work In Progress', { duration: 3000, icon: '⚒️' });
            }}
          >
            {props?.noteButtonText}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Nav
        variant='pills'
        activeKey='1'
        style={{ justifyContent: 'space-between', padding: 10 }}
      >
        <Nav.Item>
          <Nav.Link
            eventKey='1'
            href='#/home'
            style={{
              backgroundColor: '#548B88',
              color: '#FFFFFF',
              fontWeight: 700,
              fontFamily: 'Trade-Gothic-LT-Bold',
              fontSize: '20px',
            }}
          >
            Provenance
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            variant='pills'
            eventKey='2'
            title='Item'
            style={{
              backgroundColor: '#9E9E9E',
              fontWeight: 700,
              color: '#5D5D5D',
              fontFamily: 'Trade-Gothic-LT-Bold',
              fontSize: '20px',
            }}
          >
            Environment
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey='3'
            style={{
              backgroundColor: '#9E9E9E',
              fontWeight: 700,
              color: '#5D5D5D',
              fontFamily: 'Trade-Gothic-LT-Bold',
              fontSize: '20px',
            }}
          >
            Accolades
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {_renderImage()}
      {_rederImageTitle()}
      {_rederImageSubtitle()}
      {_renderNote()}
    </div>
  );
};

Provenance.defaultProps = {
  provImage: 'https://i.ibb.co/MP6c19D/godawan-bottle-branch.png',
  imageTitle: 'Curious to know the roots ?',
  imageSubtitle: 'Tap gently the images to know its provenance!',
  noteTitle: 'Six-row Barley',
  noteContent:
    'The flora of Rajasthan, the arid land of extremes. The locally source adds unique taste to Godawan and also sustainable',
  noteButtonText: 'Tap to know more! >>',
};
