import React from 'react';
import * as S from './styles';
import { Image, Button } from 'react-bootstrap';

export const Landing = (props: any) => {
  const _renderImage = () => {
    return (
      <div style={S.ImageContainerStyle}>
        <Image src={props?.productLogo} />
      </div>
    );
  };

  const _renderTitle = () => {
    return <div style={S.TitleStyle}>{props?.title}</div>;
  };

  const _renderContent = () => {
    return (
      <div
        style={S.ContentStyle}
        dangerouslySetInnerHTML={{ __html: props?.content }}
      />
    );
  };

  const _renderButton = () => {
    return (
      <div style={S.ButtonContainerStyle}>
        <Button
          onClick={props?.onVerifyClick}
          style={{
            backgroundColor: '#F3C375',
            color: '#5D5D5D',
            borderRadius: '20px',
            width: '80%',
            fontFamily: 'Trade-Gothic-LT-Bold',
            fontSize: '20px',
          }}
        >
          {props?.buttonText}
        </Button>
      </div>
    );
  };

  return (
    <div>
      {_renderImage()}
      {_renderTitle()}
      <div style={S.HorizontalLineContainerStyle}>
        <hr style={S.HorizontalLineStyle} />
      </div>
      {_renderContent()}
      {_renderButton()}
    </div>
  );
};

Landing.defaultProps = {
  productLogo:
    'https://www.godawansinglemalt.com/assests/images/born-nurtured.png',
  title: 'The mark of our Heritage',
  content:
    'Once upon a time, Godawan or the Great Indian Bustard could be seen all over India. Now only 150 remain. Rajasthan is now its sole and natural home, thanks to the longstanding tradition of its people to live in harmony with nature. It is the living embodiment of our culture of preservation and therefore the rightful and proud mark of our single malt.',
  buttonText: 'Verify Authenticity',
};
