import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Image, Badge } from 'react-bootstrap';
import { ListTable } from '../ListTable';
import { ListItem, Avatar } from 'react-native-elements';

export const SuccessModal = (props: any) => {
  const keyExtractor = (item, index) => index.toString();
  const ListTableData = {
    list: [
      {
        name: 'Barcode',
        key: 'barcode',
        avatar: 'BARCODE_LOGO',
        avatarColor: '#2F6A3E',
      },
      {
        name: 'Batch',
        key: 'batch',
        avatar: 'BATCH_LOGO',
        avatarColor: '#2F6A3E',
      },
      {
        name: 'Mfg. Date',
        key: 'mfd',
        avatar: 'DATE_LOGO',
        avatarColor: '#2F6A3E',
      },
    ],
  };

  // const _renderItem = ({ item, index }: any) => {
  //   const _getAvatarSrc = () => {
  //     if (isValidHttpUrl(item?.avatar)) {
  //       return { uri: item.avatar };
  //     }
  //     return item.avatar;
  //   };
  //   return (
  //     <ListItem
  //       bottomDivider
  //       //containerStyle={_getContainerStyle()}
  //     >
  //       <Avatar
  //         source={_getAvatarSrc()}
  //         avatarStyle={{ tintColor: item?.avatarColor }}
  //       />
  //       <ListItem.Content>
  //     </ListItem>
  //   );
  // };

  // const _renderList = () => {
  //   return (
  //     <View style={{ marginTop: 15 }}>
  //       <FlatList
  //         keyExtractor={keyExtractor}
  //         data={ListTableData?.list}
  //         renderItem={_renderItem()}
  //       />
  //     </View>
  //   );
  // };

  return (
    <View
      style={{
        padding: '20px',
        overflow: 'scroll',
      }}
    >
      <div style={{ background: '#D0AF83' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '20px',
          }}
        >
          <Badge
            bg='secondary'
            onClick={props?.onClose}
            style={{ fontSize: 26 }}
          >
            X
          </Badge>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Image
            src={
              'https://i.ibb.co/qnHrFWk/undraw-Certification-re-ifll-prev-ui.png'
            }
            width={150}
          />
        </div>
      </div>
      <div
        style={{
          paddingTop: '30px',
          borderRadius: '20px',
          borderTop: '2px solid #73AD21',
          backgroundImage: `https://www.godawansinglemalt.com/assests/images/background.jpg`,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '30px',
            paddingBottom: '30px',
          }}
        >
          <Image
            src={'https://i.ibb.co/bF6RvzL/bespoke-pattern-godawan.png'}
            width={70}
          />
        </div>
        {/* {_renderList()} */}
        <ListTable
          staticData={ListTableData}
          batch={'16'}
          mfd={'29.04.2022'}
          barcode={'8902967200115'}
        />
        <div
          style={{
            fontSize: '26px',
            fontFamily: 'Trade-Gothic-LT-Bold',
            padding: '30px',
            textAlign: 'center',
          }}
        >
          {props?.successMessage}{' '}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Image
            //src={'https://i.ibb.co/h2PwQDF/godawan-bottle.png'}
            src={'https://i.ibb.co/CKw8YxW/Screenshot-2023-06-21-102214.png'}
            width={'25%'}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '30px',
            paddingBottom: '30px',
          }}
        >
          <Image
            src={'https://i.ibb.co/bF6RvzL/bespoke-pattern-godawan.png'}
            width={70}
          />
        </div>
      </div>
    </View>
  );
};

SuccessModal.defaultProps = {
  successMessage: 'The product is Authentic',
};

const styles = StyleSheet.create({
  containerStyle: {
    // display: 'flex',
    // justifyContent: 'center',
  },
});
