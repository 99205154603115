export const ImageContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '40px',
};

export const TitleStyle = {
  fontSize: '14px',
  fontWeight: 500,
  textAlign: 'center',
};

export const SubtitleStyle = {
  fontSize: '14px',
  fontWeight: 700,
  textAlign: 'center',
};

export const NoteContainerStyle = {
  margin: 20,
  borderRadius: 20,
  minHeight: '100px',
  border: '1px solid',
  backgroundColor: '#FDE0B4',
  padding: '10px',
};

export const NoteTitleStyle = {
  fontSize: '18px',
  fontWeight: 700,
  textAlign: 'left',
};

export const NoteContentStyle = {
  fontSize: '14px',
  fontWeight: 500,
  textAlign: 'left',
};

export const NoteButtonContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
};

export const NoteButtonStyle = {
  fontSize: '14px',
  fontWeight: 500,
  textAlign: 'left',
  color: '#548B88',
};
