export const TitleStyle = {
  fontSize: '32px',
  fontWeight: 'bold',
  color: '#548B88',
  textAlign: 'center',
  fontFamily: 'Trade-Gothic-LT-Bold',
};

export const ImageContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '40px',
};

export const HorizontalLineStyle = {
  color: '#548B88',
  height: '2px',
  width: '20%',
};

export const HorizontalLineContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
};

export const ContainerStyle = {};

export const ContentStyle = {
  padding: '24px',
  //textAlign: 'center',
  //fontWeight: 500,
  fontFamily: 'Trade-Gothic-LT-Bold',
};

export const ButtonContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
};
