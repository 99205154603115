import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Image, Badge } from 'react-bootstrap';

export const FailureModal = (props: any) => {
  return (
    <View
      style={{
        padding: '20px',
        overflow: 'scroll',
      }}
    >
      <div style={{ background: '#D0AF83' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '20px',
          }}
        >
          <Badge
            bg='secondary'
            onClick={props?.onClose}
            style={{ fontSize: 26 }}
          >
            X
          </Badge>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Image
            src={
              'https://i.ibb.co/5B4Prxc/undraw-void-3ggu-Photo-Room-png-Photo-Room.png'
            }
            width={150}
          />
        </div>
      </div>
      <div
        style={{
          paddingTop: '30px',
          borderRadius: '20px',
          borderTop: '2px solid #73AD21',
          backgroundImage: `https://www.godawansinglemalt.com/assests/images/background.jpg`,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '30px',
            paddingBottom: '30px',
          }}
        >
          <Image
            src={'https://i.ibb.co/bF6RvzL/bespoke-pattern-godawan.png'}
            width={70}
          />
        </div>
        <div
          style={{
            fontSize: '26px',
            fontFamily: 'Trade-Gothic-LT-Bold',
            padding: '30px',
            textAlign: 'center',
          }}
        >
          {props?.failureMessage}{' '}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Image
            src={'https://i.ibb.co/wJj6vCZ/godawan-bottle-2.png'}
            width={'25%'}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '30px',
            paddingBottom: '30px',
          }}
        >
          <Image
            src={'https://i.ibb.co/bF6RvzL/bespoke-pattern-godawan.png'}
            width={70}
          />
        </div>
        <div style={{ fontSize: '22px', fontWeight: 600 }}>Deviations</div>
        <div
          style={{ fontSize: '22px' }}
          dangerouslySetInnerHTML={{ __html: props?.failureContent }}
        />
      </div>
    </View>
  );
};

FailureModal.defaultProps = {
  failureMessage:
    'This Product is probably a counterfeit. Proceed with caution.',
  failureContent:
    '<ul><li>Pattern colour mismatch</li><li>Unknown character on the label</li><li>Font mismatch</li></ul>',
};

const styles = StyleSheet.create({
  containerStyle: {
    // display: 'flex',
    // justifyContent: 'center',
  },
});
